import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { Stack, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper, Numeric } from '@components/controls/react-hook-form-friendly/smart';
import { z } from 'zod';
import ProductFallback from '@assets/svg/Article fallback.svg';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputAdornment from '@mui/material/InputAdornment';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import Divider from '@mui/material/Divider';
import { useGetProductCategories } from '@services/api/productCategories/productCategories';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LABEL_POSITION } from '@components/controls/react-hook-form-friendly/dumb';
import { useDeleteProjectProduct } from '@services/api/projectProducts/projectProductDelete';
import { useGetProjectProductById } from '@services/api/projectProducts/projectProductGet';
import { mdiTrashCanOutline } from '@mdi/js';
import {
  CreateProjectProductRequest,
  ProductExistenceDot,
  UpdateProjectProductRequest,
} from '@generatedTypes/data-contracts';
import { PutProjectProductProps } from '@services/api/projectProducts/projectProductEdit';
import { useSelector } from 'react-redux';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { ListItemBordered } from '@components/ListMui/ListItemBordered';
import { DevTool } from '@hookform/devtools';
import { EnumberSearch } from '@components/EnumberSearch/EnumberSearch';
import { AutocompleteWrapper } from '@components/controls/react-hook-form-friendly/dumb/Autocomplete/AutocompleteWrapper';

const editCustomProductSchema = z.object({
  name: z.string().min(1),
  category: z.string().min(1),
  eNumber: z.string().min(1),
  warranty: z.coerce.number(),
  amount: z.coerce.number().min(1),
  unit: z.string(),
});

export type EditCustomProductValues = z.infer<typeof editCustomProductSchema>;

const defaultValues: EditCustomProductValues = {
  name: ``,
  category: ``,
  eNumber: ``,
  warranty: 0,
  amount: 1,
  unit: ``,
};

type AddEditCustomProductFormProps = {
  onClose: () => void;
  disableForm?: boolean;
  onSubmitProjectProduct: (projectProduct: PutProjectProductProps | CreateProjectProductRequest) => void;
  refetchLeadProjects: () => void;
  beValidationResults?: ParsedBackendValidationResults | null;
};

export const AddEditCustomProductForm: React.FC<AddEditCustomProductFormProps> = ({
  disableForm,
  onClose,
  onSubmitProjectProduct,
  refetchLeadProjects,
  beValidationResults,
}) => {
  const {
    translate,
    translations: {
      editCustomProduct,
      common: { year },
    },
  } = useTranslations();
  const { projectProduct } = useGetProjectProductById();
  const projectId = useSelector(selectCurrentProjectId);
  const [productExistence, setProductExistence] = useState<ProductExistenceDot | null | undefined>(undefined);
  const productAlreadyExists = !!productExistence?.productId;
  const productFound = productExistence?.exists;

  const { deleteProjectProduct, isDeletingProjectProduct } = useDeleteProjectProduct({
    onSuccess: () => {
      onClose();
      refetchLeadProjects();
    },
  });
  const formDisabled = disableForm || isDeletingProjectProduct;

  const { control, handleSubmit, setError, reset, watch } = useForm({
    resolver: zodResolver(editCustomProductSchema),
    defaultValues: {
      name: projectProduct?.productName ?? defaultValues.name,
      category: projectProduct?.productCategoryName ?? defaultValues.category,
      eNumber: projectProduct?.productENumber ?? defaultValues.eNumber,
      warranty: projectProduct?.productWarranty ?? defaultValues.warranty,
      amount: projectProduct?.quantity ?? defaultValues.amount,
      unit: projectProduct?.unit ?? defaultValues.unit,
    },
  });

  useEffect(() => {
    if (projectProduct) {
      reset({
        name: projectProduct.productName,
        category: projectProduct.productCategoryName,
        eNumber: projectProduct.productENumber ?? undefined,
        warranty: projectProduct.productWarranty ?? undefined,
        amount: projectProduct.quantity,
        unit: projectProduct.unit,
      });
    }
  }, [projectProduct, reset]);

  const [name, warranty, eNumber, category] = watch([`name`, `warranty`, `eNumber`, `category`]);

  useEffect(() => {
    if (beValidationResults) {
      Object.entries(beValidationResults.errors).forEach(([key, error]) => {
        setError(key as keyof EditCustomProductValues, { message: error[0] });
      });
    }
  }, [beValidationResults, setError]);

  const { productCategories } = useGetProductCategories();
  const productCategoriesOptions = useMemo(
    () => Array.from(new Set(productCategories.map((category) => category.name)).values()),
    [productCategories],
  );

  const deleteProjectProductHandler = useCallback(() => {
    deleteProjectProduct(projectProduct?.id ?? 0);
  }, [projectProduct?.id, deleteProjectProduct]);

  const onSubmit = useCallback(
    (values: EditCustomProductValues) => {
      const submitObject: UpdateProjectProductRequest | CreateProjectProductRequest = {
        quantity: values.amount,
        isExcluded: false,
        customProduct: {
          name: values.name,
          category: values.category,
          eNumber: values.eNumber,
          warranty: values.warranty,
          unit: values.unit,
        },
      };

      if (projectProduct) {
        onSubmitProjectProduct({
          ...submitObject,
          id: projectProduct?.id,
        });
      } else if (projectId) {
        onSubmitProjectProduct({
          ...submitObject,
          projectId,
        });
      }
    },
    [onSubmitProjectProduct, projectId, projectProduct],
  );

  return (
    <FormWrapper
      title={translate(editCustomProduct.header)}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onClose}
      disabled={formDisabled || productAlreadyExists || (!productFound && !projectProduct)}
      titleAction={projectProduct ? deleteProjectProductHandler : undefined}
      iconPath={projectProduct ? mdiTrashCanOutline : undefined}
    >
      <Stack spacing={2}>
        <EnumberSearch
          type="number"
          control={control}
          name="eNumber"
          label={translate(editCustomProduct.eNumber)}
          isRequired
          onEnumberSearch={setProductExistence}
        />
        {((productFound && !productAlreadyExists) || projectProduct) && (
          <>
            <Divider />
            <Typography variant="h3">{translate(editCustomProduct.materialInformationHeader)}</Typography>
            <Stack>
              <Typography>{translate(editCustomProduct.eNumber)}</Typography>
              <Typography>{eNumber}</Typography>
            </Stack>
            <InputWrapper control={control} name="name" label={translate(editCustomProduct.name)} isRequired />
            <AutocompleteWrapper
              options={productCategoriesOptions}
              control={control}
              name="category"
              label={translate(editCustomProduct.category)}
              freeSolo
              isRequired
            />
            <MuiNumberInput
              control={control}
              name="warranty"
              label={translate(editCustomProduct.warranty)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{translate(year)}</InputAdornment>,
              }}
            />
            <Numeric
              control={control}
              name="amount"
              label={translate(editCustomProduct.amount)}
              labelPosition={LABEL_POSITION.STRETCH_FULL_LINE}
              min={1}
              precision={0}
            />
            <InputWrapper control={control} name="unit" label={translate(editCustomProduct.unit)} isRequired />
            <Divider />
            <ListItemBordered
              title={category || translate(editCustomProduct.defaultCategory)}
              backgroundColor="white"
              selected
              subtitle={
                <Stack>
                  <Typography variant="subtitle1">{name || translate(editCustomProduct.defaultName)}</Typography>
                  <Typography variant="subtitle1">
                    {`${warranty || `x`} ${translate(editCustomProduct.warrantySuffix)}`}
                  </Typography>
                </Stack>
              }
              avatar={
                <img
                  src={productExistence?.imageUrl ?? projectProduct?.productImageUrl ?? ProductFallback}
                  alt="product image"
                />
              }
            />
          </>
        )}
      </Stack>
      <DevTool control={control} />
    </FormWrapper>
  );
};
